import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { tokens } from "./../theme";
import RefContact from "../Components/Referral/RefContact";
import RefCode from "../Components/Referral/RefCode";
import RefCredit from "../Components/Referral/RefCredit";
import RefRedeem from "../Components/Referral/RefRedeem";

function Referral() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  function Page() {
    return activeTab === 1 ? (
      <RefContact />
    ) : activeTab === 2 ? (
      <RefCode />
    ) : activeTab === 3 ? (
      <RefCredit />
    ) : activeTab === 4 ? (
      <RefRedeem />
    ) : (
      "hello"
    );
  }

  return (
    <div>
      <Box>
        {" "}
        <Typography
          variant="h2"
          component={"h2"}
          fontWeight={500}
          sx={{ textAlign: "center", pb: "8px" }}
        >
          Referral
        </Typography>
        <Divider />
        <Box mt={5}>
          <Box display={"flex"} justifyContent={"center"}>
            <Button
              variant="text"
              color={"primary"}
              sx={
                activeTab === 1
                  ? {
                      fontWeight: "600",
                      paddingX: "30px",
                      borderBottom: `2px solid ${colors.grey[100]}`,
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                    }
                  : { fontWeight: "600", paddingX: "30px" }
              }
              onClick={() => {
                handleTabClick(1);
              }}
            >
              Referred Contacts
            </Button>
            <Button
              variant="text"
              color={"primary"}
              sx={
                activeTab === 2
                  ? {
                      fontWeight: "600",
                      paddingX: "30px",
                      borderBottom: `2px solid ${colors.grey[100]}`,
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                    }
                  : { fontWeight: "600", paddingX: "30px" }
              }
              onClick={() => {
                handleTabClick(2);
              }}
            >
              Referral Code
            </Button>
            <Button
              variant="text"
              color={"primary"}
              sx={
                activeTab === 3
                  ? {
                      fontWeight: "600",
                      paddingX: "30px",
                      borderBottom: `2px solid ${colors.grey[100]}`,
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                    }
                  : { fontWeight: "600", paddingX: "30px" }
              }
              onClick={() => {
                handleTabClick(3);
              }}
            >
              Referral Credits
            </Button>
            <Button
              variant="text"
              color={"primary"}
              sx={
                activeTab === 4
                  ? {
                      fontWeight: "600",
                      paddingX: "30px",
                      borderBottom: `2px solid ${colors.grey[100]}`,
                      borderBottomLeftRadius: 0,
                      borderBottomRightRadius: 0,
                    }
                  : { fontWeight: "600", paddingX: "30px" }
              }
              onClick={() => {
                handleTabClick(4);
              }}
            >
              Redeem Bottles
            </Button>
          </Box>
          <Divider sx={{ mt: "-1px" }} />
        </Box>
      </Box>
      <Box>{Page()}</Box>
    </div>
  );
}

export default Referral;
